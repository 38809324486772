import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled, { css } from 'styled-components'
import LinkConfig from '../types/LinkConfig'
import { getLinkConfig } from '../utils'
import Button from './Button'
import LandingPageHeading from './LandingPageHeading'
import LandingPageSection from './LandingPageSection'
import Lead from './Lead'
import PagePadding from './PagePadding'
import RichText from './RichText'

export enum LayoutType {
  imageOnRight = 'imageOnRight',
  imageOnLeft = 'imageOnLeft',
}

type CallToActionType = {
  displayText: string
  url: string
}

type Props = {
  heading: string
  subheading: string
  imageSrc: any
  imageAlt: string
  text: any[]
  hasBackground?: boolean
  layout?: LayoutType
  callToAction?: CallToActionType
}

const CallToActionSection: React.FC<Props> = ({
  heading,
  subheading,
  imageSrc,
  imageAlt,
  text,
  hasBackground = false,
  layout = LayoutType.imageOnRight,
  callToAction,
}) => {
  let callToActionLink: LinkConfig
  if (callToAction) {
    callToActionLink = getLinkConfig(callToAction.url)
  }
  return (
    <LandingPageSection hasBackground={hasBackground}>
      <Flex>
        <TextContent layout={layout}>
          <LandingPageHeading as="h2">{heading}</LandingPageHeading>
          <LeadWithLayout>{subheading}</LeadWithLayout>
          <PortableTextLayout>
            <RichText value={text} />
          </PortableTextLayout>
          {callToAction && (
            <Button
              as={callToActionLink.component}
              to={
                callToActionLink.isInternal ? callToActionLink.url : undefined
              }
              href={
                callToActionLink.isExternal ? callToActionLink.url : undefined
              }
            >
              {callToAction.displayText}
            </Button>
          )}
        </TextContent>
        <Image image={imageSrc} alt={imageAlt || ''} />
      </Flex>
    </LandingPageSection>
  )
}

export default CallToActionSection

const breakpoint = 900

const Flex = styled(PagePadding)`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

const TextContent = styled.div<{ layout: LayoutType }>`
  text-align: center;

  @media (min-width: ${breakpoint}px) {
    text-align: unset;
    flex: 1;
    ${props =>
      props.layout === LayoutType.imageOnLeft
        ? css`
            margin-left: 64px;
            order: 1;
          `
        : css`
            margin-right: 64px;
            text-align: right;
          `}
  }
`

const LeadWithLayout = styled(Lead)``

const PortableTextLayout = styled.div`
  font-size: 0.875rem;
  line-height: (25.6 / 14) em;
  margin: 2.5rem 0 3.25rem;
`

const Image = styled(GatsbyImage)<{ image: any }>`
  max-width: 100%;
  width: 450px;
  margin: 1.5rem auto 0;

  @media (min-width: ${breakpoint}px) {
    margin: 0;
  }
`
