import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import CallToActionSection from '../components/CallToActionSection'
import { GridList, GridListItem } from '../components/GridList'
import HoneybookInquiryForm from '../components/HoneybookInquiryForm'
import LandingPageHeading from '../components/LandingPageHeading'
import Layout from '../components/Layout'
import PagePadding from '../components/PagePadding'
import RichText from '../components/RichText'
import Seo from '../components/Seo'
import SerifText from '../components/SerifText'
import PageData from '../types/PageData'
import { handleUnsupportedSection } from '../utils'

interface Props {
  data: ProcessPageQuery
}

type ProcessPageQuery = {
  sanityPage: PageData & {
    // TODO: Add types
    sections: any[]
    _rawSections: {
      _key: string
      text: any[]
    }[]
  }
}

function ProcessPage({ data }: Props) {
  return (
    <Layout pageData={data?.sanityPage} pageTitle="The Process">
      <Seo
        title="The Process"
        description="My collection of semi-custom designs allows you to pick and choose your favourite elements, materials, ink colours and embellishments."
      />
      <Content>
        {data.sanityPage.sections.map(section => {
          // TODO: Abstract tooling to transform raw object to an array of portable text
          const rawSection = data.sanityPage._rawSections.find(
            raw => raw._key === section._key
          )
          switch (section._type) {
            case 'headingWithTextSection': {
              return (
                <div key={section._key}>
                  <LandingPageHeading centered>
                    {section.heading}
                  </LandingPageHeading>
                  <RichText
                    value={rawSection.text}
                    components={{
                      block: {
                        normal: SerifText,
                      },
                    }}
                  />
                </div>
              )
            }
            case 'imageSection': {
              const { _key, image } = section
              return (
                <Image
                  key={_key}
                  image={image.asset.gatsbyImageData}
                  alt={image.asset.alt}
                />
              )
            }
            case 'landingPageSection': {
              const {
                _key,
                heading,
                subheading,
                image,
                hasBackground,
                layout,
                callToAction,
              } = section
              return (
                <CallToActionSection
                  key={_key}
                  heading={heading}
                  subheading={subheading}
                  imageSrc={image.asset.gatsbyImageData}
                  imageAlt={image.alt}
                  hasBackground={hasBackground}
                  layout={layout}
                  text={rawSection.text}
                  callToAction={callToAction}
                />
              )
            }
            case 'overviewGridSection': {
              const { _key, items } = section
              return (
                <GridList key={_key}>
                  {items.map(item => (
                    <GridListItem key={item._key}>
                      <LandingPageHeading>{item.heading}</LandingPageHeading>
                      <RichText
                        value={item._rawText}
                        components={{
                          block: {
                            normal: SerifText,
                          },
                        }}
                      />
                    </GridListItem>
                  ))}
                </GridList>
              )
            }
            default: {
              handleUnsupportedSection(section._type)
              return null
            }
          }
        })}
        <HoneybookInquiryForm />
      </Content>
    </Layout>
  )
}

export default ProcessPage

export const query = graphql`
  query ProcessPageQuery {
    sanityPage(uuid: { eq: "semi-custom-process" }) {
      _rawPageMessage
      _rawSections
      sections {
        ... on SanityHeadingWithTextSection {
          _key
          _type
          heading
        }
        ... on SanityImageSection {
          _key
          _type
          image {
            alt
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                width: 1168
                height: 375
              )
            }
          }
        }
        ... on SanityLandingPageSection {
          _key
          _type
          image {
            alt
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                width: 450
              )
            }
          }
          hasBackground
          heading
          layout
          subheading
          callToAction {
            displayText
            url
          }
        }
        ... on SanityOverviewGridSection {
          _key
          _type
          items {
            _key
            heading
            _rawText
          }
        }
      }
    }
  }
`

const Content = styled(PagePadding)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const Image = styled(GatsbyImage)`
  display: block;
  margin-bottom: 3rem;
`
